<template>
  <div>
    <helloWorld />
    <div class="content-520">
      <p class="helpTop">
        <span></span>
        帮助中心
      </p>
      <div class="helpMain">
        <ul class="clearfix topList">
          <li @click="toDetail(1)">
            <img src="@/assets/a1.png" alt="" style="width:100%">
            <div class="desc">
              <h5>创建账号教程</h5>
              <p>创建平台账号，提交身份资料，进行身份认证。</p>
            </div>
          </li>
          <li @click="toDetail(2)">
            <img src="@/assets/a2.png" alt="" style="width:100%">
            <div class="desc">
              <h5>购买数字藏品教程</h5>
              <p>了解商品的拍卖和购买流程，帮您完成购买心仪的数字藏品的愿望。</p>
            </div>
          </li>
          <li @click="toDetail(3)">
            <img src="@/assets/a3.png" alt="" style="width:100%">
            <div class="desc">
              <h5>发布数字藏品教程</h5>
              <p>上传您的作品（图像、或3D艺术），添加标题和描述，并自定义您的 数字藏品，包括属性、统计数据和可解锁的内容</p>
            </div>
          </li>
        </ul>
        <h3>猜你想问</h3>
        <ul class="helpList">
          <li @click="toDetail(1)"> 如何创建一个新账户 </li>
          <li @click="toDetail(2)"> 如何购买数字藏品？ </li>
          <li @click="toDetail(3)"> 如何创造数字藏品？ </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import helloWorld from '@/components/tabBar'
export default {
  name: 'Index',
  components: {
    helloWorld
  },
  mounted() {
  },
  data() {
    return {
    }
  },
  methods: {
    toDetail(val) {
      this.$router.push('./helpDetail?id=' + val)
    }
  }
}
</script>
<style scoped>
h5 {
    display: block;
    font-size: 0.83em;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: inherit;
}
.content-520 {
  max-width: 888px;
  margin: auto;
  padding-top: 50px;
}
.helpTop{
  display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    color: #1d202b;
    margin-bottom: 20px;
}
.helpTop span{
  width: 4px;
    height: 20px;
    background: #3d61ff;
    display: inline-block;
    margin-right: 10px;
}
.helpMain {
    max-width: 888px;
    margin: 0 auto;
    /* padding: 0 16px; */
}
.helpMain ul.topList {
    margin-bottom: 60px;
    height: 251px;
}
.clearfix {
    zoom: 1;
}
.clearfix::before, .clearfix::after {
    display: table;
    content: '';
}
.helpMain ul.topList li {
    max-width: 264px;
    height: 251px;
    background: #ffffff;
    border-radius: 5px;
    border: 1px solid #e4e8ea;
    float: left;
    margin-right: 20px;
    cursor: pointer;
}
.helpMain ul.topList li .pic {
    background-size: contain !important;
    height: 176px;
    border-radius: 5px 5px 0 0;
}
.helpMain ul.topList li .desc {
    padding: 6px 22px;
}
.helpMain ul.topList li .desc p {
    font-size: 9px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #9b9b9b;
    line-height: 15px;
    letter-spacing: 0;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-top:6px;
}
.helpMain > h3 {
    max-width: 840px;
    font-size: 18px;
    font-weight: 400;
    color: #303030;
    padding-bottom: 20px;
    border-bottom: 1px solid #eeeeee;
    position: relative;
    margin-block-start: 0em;
    margin-block-end: 0em;
}
.helpMain > h3::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 401px;
    height: 2px;
    background: #2f54eb;
}
.helpMain > ul.helpList {
    /* min-height: 840px; */
}
.helpMain > ul.helpList li {
    font-size: 14px;
    font-weight: 400;
    color: #1d202b;
    padding: 22px 0;
    cursor: pointer;
    border-bottom: 1px solid #eeeeee;
}
@media only screen and (min-width: 590px) and (max-width: 880px){
  .helpMain ul.topList{
    height: 510px;
    margin: auto;
    text-align: center;
  }
}
@media screen and (max-width: 590px) {
  .helpMain ul.topList{
    height: 760px;
    margin: auto;
    text-align: center;
  }
}
</style>